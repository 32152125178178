import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../Context';
import { usePermissions } from '../../services';
import { ScheduledOrderByDateType } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@mui/material';
import DataTable from '../../components/shared/DataTable';
import { Button } from '../../components/shared/FormElements';

const ViewScheduledDate = () => {
  const { dated } = useParams();
  const { apiCall } = useAppContext();
  const navigate = useNavigate();
  const [orders, setOrders] = useState<ScheduledOrderByDateType[]>([]);
  const [total_booked_clothing, setTotalBookedClothing] = useState<number>(0);

  const { canView_orders } = usePermissions(['view_orders']);

  useEffect(
    () => {
      if (!dated || dated === undefined || dated === null || !canView_orders) {
        navigate('/');
        return;
      }
      getScheduledByDate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getScheduledByDate = async () => {
    const response = await apiCall('GET', `orders/scheduled/${dated}/`, null, '', 'Error al obtener las ordenes');
    if (response) {
      let total_booked_clothing = 0;
      response.forEach((order: ScheduledOrderByDateType) => {
        total_booked_clothing += order.total_clothing_quantity;
      });
      setTotalBookedClothing(total_booked_clothing);
      setOrders(response);
    }
  };

  const columns = [
    { title: 'Orden', field: 'id', cell_class: 'text-center' },
    { title: 'Deporte', field: 'line.name', cell_class: 'text-center' },
    { title: 'Grupo OT', field: 'group_ot.name', cell_class: 'text-center' },
    { title: 'Cantidad', field: 'total_clothing_quantity', cell_class: 'text-center' },
    { title: 'Dias Agendados', field: 'scheduled_days', cell_class: 'text-center' },
  ];

  const actions: Array<any> = [
    {
      icon: <FontAwesomeIcon icon={faEye} />,
      tooltip: 'Ver',
      onClick: (rowData: ScheduledOrderByDateType) => {
        navigate(`/orden/${rowData.id}`);
      },
    },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} className='text-left'>
          <h2 className='text-left'>Agenda del día {dated ?? ''}</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          <b>No. Prendas Agendadas</b>
          <h2 style={{ margin: 0 }}>{total_booked_clothing}</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          <Button className='btn btn-primary' onClick={() => navigate('/agenda')}>
            Ir a Agenda
          </Button>
        </Grid>
      </Grid>
      <DataTable columns={columns} data={orders} actions={actions} />
    </div>
  );
};
export default ViewScheduledDate;
