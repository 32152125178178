import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Label, TextField } from '../../components/shared/FormElements';
import { Grid } from '@mui/material';

//import { useAppContext } from "../../Context";

// assets
import logoSimbolo from '../../assets/images/logo-simbolo.png';
//import { useAppDispatch } from "../../hooks";

const FormForOrderId: React.FC = () => {
  const [token, setToken] = useState('');
  const checkTokenForList = () => {};
  return (
    <Grid container className='userforms'>
      <Grid item xs={12} sm={12} md={12}>
        <img src={logoSimbolo} alt='logo' className='App-logo' />
        <h1>Lista de Nombres y números</h1>
        <h2>Aún debemos comprobar algo antes de continuar</h2>
        <p className='text-center'>
          Pide al vendedor el <b>token</b> de tu orden para poder agregar la lista de nombres y digítalo a continuación
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <form>
          <div className='form-group'>
            <Label htmlFor='token'>Token</Label>
            <TextField
              type='text'
              name='token'
              id='token'
              className='input'
              value={token}
              onChange={(e: { target: { value: string } }) => setToken(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <Button type='button' className='button' onClick={checkTokenForList}>
              Continuar
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

const FormForNames: React.FC = () => {
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const addNameToList = () => {
    console.log('Agregando nombre a la lista');
  };
  return (
    <div>
      <h2>Agrega los nombres y números</h2>
      <p>Ingresa los nombres y números de los participantes de la orden</p>
      <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
      <input type='text' value={number} onChange={(e) => setNumber(e.target.value)} />
      <Button onClick={addNameToList}>Agregar</Button>
    </div>
  );
};

const ViewListName: React.FC = () => {
  const { token } = useParams();

  //const { apiCall } = useAppContext();
  //const dispatch =  useAppDispatch();

  useEffect(
    () => {
      const getOrders = async () => {
        /*const response = await apiCall('GET', 'list//', null, '', 'Error al obtener las ordenes');
        if (response) {
            dispatch(setOrdersToValidate(response));
        }*/
      };

      getOrders();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <div>{!token ? <FormForOrderId /> : <FormForNames />}</div>;
};

export default ViewListName;
