import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { useAppContext } from '../../Context';
import { AttributesType, ColumnsType, SketchRequestType } from '../../types';
import { useEffect, useState } from 'react';
import { useGroups, usePermissions } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@mui/material';
import { Button } from '../../components/shared/FormElements';
import { initialStateSketch, setSketch } from '../../slices/sketchesSlice';
import DataTable from '../../components/shared/DataTable';
import { setPopConfirmation } from '../../slices/miscSlice';
import { constants } from '../../constants';

const ViewSketches: React.FC<{ approbed: boolean }> = ({ approbed }) => {
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sketches, setSketches] = useState<SketchRequestType[]>([]);

  const { canView_sketchrequests, canChange_sketchrequests, canAdd_sketchrequests, canDelete_sketchrequests } =
    usePermissions(['view_sketchrequests', 'add_sketchrequests', 'change_sketchrequests', 'delete_sketchrequests']);

  const { isVendedor } = useGroups(['Vendedor']);

  useEffect(
    () => {
      getSketches();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getSketches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approbed]);

  if (!canView_sketchrequests) {
    navigate('/');
    return null;
  }

  const getSketches = async () => {
    const response = await apiCall('GET', 'sketches/', null, '', 'Error al obtener los bocetos');
    if (response) {
      if (approbed) {
        const aprobados = response.filter(
          (sketch: SketchRequestType) => sketch.status === constants.sketchRequestStatus.APROBADO
        );
        setSketches(aprobados);
        return;
      }
      const enProceso = response.filter(
        (sketch: SketchRequestType) => sketch.status !== constants.sketchRequestStatus.APROBADO
      );
      setSketches(enProceso);
    }
  };

  const decoratorEstado = {
    Creado: 'info',
    'En Diseño': 'warning',
    'Por Validar': 'secondary',
    Aprobado: 'success',
    'Cambios Solicitados': 'error',
    Asignado: 'success',
  };

  const columns: ColumnsType[] = [
    {
      title: 'ID',
      field: 'id',
    },
    {
      title: 'Orden',
      field: 'order_id_annotated',
    },
    {
      title: 'Cliente',
      field: 'customer_name_annotated',
    },
    {
      title: 'Diseñador',
      field: 'designer_name_annotated',
    },
    {
      title: 'Estado',
      field: 'status',
      type: 'decorator',
      decorator: decoratorEstado,
      cell_class: 'text-center',
    },
    {
      title: 'Creado por',
      field: 'created_by_username_annotated',
    },
    {
      title: 'Fecha de Creación',
      field: 'created_at',
      type: 'datetime',
    },
    {
      title: 'Fecha de Actualización',
      field: 'updated_at',
      type: 'datetime',
    },
  ];

  const actions: Array<any> = [
    canView_sketchrequests
      ? {
          icon: <FontAwesomeIcon icon={faEye} />,
          tooltip: 'Ver',
          onClick: (rowData: SketchRequestType) => {
            navigate(`/boceto/${rowData.id}`);
          },
        }
      : null,
    canChange_sketchrequests && isVendedor
      ? {
          icon: <FontAwesomeIcon icon={faEdit} />,
          tooltip: 'Solicitar Cambios',
          onClick: (rowData: SketchRequestType) => {
            // solicitarCambios(rowData);
          },
        }
      : null,
    canDelete_sketchrequests
      ? {
          icon: <FontAwesomeIcon icon={faTrash} />,
          tooltip: 'Eliminar',
          onClick: (rowData: SketchRequestType) => {
            dispatch(
              setPopConfirmation({
                show: true,
                message: '¿Está seguro de eliminar el boceto?',
                alertType: 'danger',
                okFunc: () => {
                  deleteBoceto(rowData.id);
                },
              })
            );
          },
        }
      : null,
  ];

  const deleteBoceto = async (id: number) => {
    const response = await apiCall(
      'DELETE',
      `sketch/${id}/`,
      null,
      'Boceto eliminado con éxito',
      'Error al eliminar el boceto'
    );
    if (response) {
      getSketches();
    }
  };

  const filterDate: Array<AttributesType> = [
    { value: 'created_at', name: 'Fecha de Creación' },
    { value: 'updated_at', name: 'Fecha de Actualización' },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>Bocetos</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          {canAdd_sketchrequests && (
            <Button
              className='btn btn-primary'
              onClick={() => {
                dispatch(setSketch(initialStateSketch.sketch));
                navigate('/boceto/crear');
              }}
            >
              Agregar
            </Button>
          )}
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        data={sketches}
        actions={actions}
        groupedOptionBy={'status'}
        filterByDateBy={filterDate}
      />
    </div>
  );
};

export default ViewSketches;
