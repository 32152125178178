import { Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StockType } from '../../types';
import { useAppContext } from '../../Context';
import { Button, Label } from '../../components/shared/FormElements';
import { usePermissions } from '../../services';
import { useAppDispatch } from '../../hooks';
import { setPopAlert } from '../../slices/miscSlice';

const CommentSupply: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { canChange_stock } = usePermissions(['change_stock']);
  const [commentSupply, setCommentSupply] = useState<string>('');

  const [stock, setStock] = useState<StockType>({
    id: 0,
    product_name: '',
    product_description: '',
    category: { id: 0, category_name: '' },
    measurement: { id: 0, measurement_name: '' },
    minimum_stock: 0,
    groups: [],
    process_roles: [],
  });

  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) {
      navigate('/insumos/');
      return;
    }

    // Fetch data
    getSupply(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getSupply = async (id: string) => {
    const response = await apiCall('GET', `stock/${id}/`, null, '', 'Error al obtener el stock');
    if (response) {
      setStock(response);
    }
  };

  const handleSave = async () => {
    if (commentSupply.length === 0) {
      dispatch(setPopAlert({ show: true, message: 'El texto de la novedad es requerido', alertType: 'danger' }));
      return;
    }

    const data = {
      comment: commentSupply,
    };

    const response = await apiCall('POST', `stock/comment/${id}/`, data, '', 'Error al registrar la salida');
    if (response) {
      navigate('/insumos/');
    }
  };

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='text-left'>
          <h2 className='text-left'>
            Registrar Novedad para {stock.product_name.length > 0 ? `${stock.product_name}` : null}
          </h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-right'>
          <Button className='btn btn-primary' onClick={() => navigate('/insumos/')}>
            Ir a Insumos
          </Button>
        </Grid>
      </Grid>
      <div className='form-container'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Escriba brevemente la novedad</Label>
              <TextField
                fullWidth
                multiline
                rows={14}
                value={commentSupply}
                onChange={(e) => setCommentSupply(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className='text-right'>
          {canChange_stock && (
            <Button className='btn btn-primary' onClick={handleSave}>
              Guardar
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CommentSupply;
