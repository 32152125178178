import { Grid } from '@mui/material';
import DataTable from '../../components/shared/DataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../Context';
import { StockDetailType } from '../../types';
import { Button } from '../../components/shared/FormElements';

const DetailSupply: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useAppContext();
  useEffect(() => {
    if (!id) {
      navigate('/insumos/');
      return;
    }
    // Fetch data
    getSupplyDetail(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const [stock, setStock] = useState<StockDetailType>({
    product_name: '',
    transactions: [],
    total_entrada_month: 0,
    total_salida_month: 0,
    total_pendiente_month: 0,
    total_stock: 0,
  });

  const getSupplyDetail = async (id: string) => {
    const response = await apiCall(
      'GET',
      `stock_transactions/${id}/`,
      null,
      '',
      'Error al obtener el detalle del insumo'
    );
    if (response) {
      setStock(response);
    }
  };

  const columns = [
    { title: 'Tipo', field: 'transaction_type', cell_class: 'text-center' },
    { title: 'Cantidad', field: 'product_quantity', cell_class: 'text-center' },
    { title: 'Costo Unitario', field: 'unitary_cost', cell_class: 'text-center' },
    { title: 'Fecha', field: 'created_at', cell_class: 'text-center', type: 'datetime' },
    { title: 'Ejecutado por', field: 'executed_by__username', cell_class: 'text-center' },
    { title: 'Proveedor', field: 'vendor__vendor_name', cell_class: 'text-center' },
    { title: 'Factura', field: 'invoice_number', cell_class: 'text-center' },
    { title: 'Comentario', field: 'comment', cell_class: 'text-center' },
    { title: 'Orden', field: 'order_ids', cell_class: 'text-center' },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className='text-left'>
          <h2 className='text-left'>Detalle de Insumo: {stock.product_name}</h2>
        </Grid>
        <Grid item xs={12} sm={4} className='text-center'>
          <h4 className='text-center'>
            Entradas en el mes: {stock.total_entrada_month} <br /> Salidas en el mes: {stock.total_salida_month} <br />
            Pendiente en el mes: {stock.total_pendiente_month} <br /> Total en Stock: {stock.total_stock}
          </h4>
        </Grid>
        <Grid item xs={12} sm={4} className='text-right'>
          <Button className='btn btn-primary' onClick={() => navigate('/insumos/')}>
            Ir a Insumos
          </Button>
        </Grid>
      </Grid>
      <DataTable columns={columns} data={stock?.transactions} actions={null} groupedOptionBy={'category_name'} />
    </div>
  );
};

export default DetailSupply;
