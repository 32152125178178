import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../Context';
import { CategoryType, GroupType, MeasurementType, ProcessRoleType, StockType } from '../../types';
import { usePermissions } from '../../services';
import { Button, Label } from '../../components/shared/FormElements';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setGroups, setProcessRoles } from '../../slices/permissionsSlice';
import { setPopAlert } from '../../slices/miscSlice';

const CreateOrEditStock: React.FC = () => {
  const { id } = useParams();
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();

  const [enableNewCategory, setEnableNewCategory] = useState(false);
  const [enableNewMeasurement, setEnableNewMeasurement] = useState(false);

  const groups: Array<GroupType> = useAppSelector((state) => state.permissions.groups);
  const process_roles: Array<ProcessRoleType> = useAppSelector((state) => state.permissions.process_roles);

  const [stock, setStock] = useState<StockType>({
    id: 0,
    product_name: '',
    product_description: '',
    category: { id: 0, category_name: '' },
    measurement: { id: 0, measurement_name: '' },
    minimum_stock: 0,
    groups: [],
    process_roles: [],
  });
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [measurements, setMeasurements] = useState<MeasurementType[]>([]);

  const navigate = useNavigate();

  const { canAdd_stock, canChange_stock } = usePermissions(['add_stock', 'change_stock']);

  useEffect(() => {
    if (id) {
      if (!canChange_stock) {
        navigate('/');
        return;
      }
      getProduct();
    } else {
      if (!canAdd_stock) {
        navigate('/');
        return;
      }
    }

    getInitialData();
    getGroups();
    getProcessRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProduct = async () => {
    const response = await apiCall('GET', `stock/${id}/`, null, '', 'Error al obtener el stock');
    if (response) {
      setStock(response);
    }
  };

  const getInitialData = async () => {
    const response = await apiCall('GET', 'stock/initial_data/', null, '', 'Error al obtener los datos iniciales');
    if (response) {
      setCategories(response.categories);
      setMeasurements(response.measurements);
    }
  };

  const getGroups = async () => {
    const response = await apiCall('GET', 'groups/', null, '', 'Error al obtener los roles');
    if (response) {
      dispatch(setGroups(response));
    }
  };

  const getProcessRoles = async () => {
    const response = await apiCall('GET', 'process_roles/', null, '', 'Error al obtener los roles de procesos');
    if (response) {
      dispatch(setProcessRoles(response));
    }
  };

  const handleSave = async () => {
    if (stock.category.category_name === '') {
      dispatch(
        setPopAlert({ show: true, message: 'Por favor seleccione una categoría o cree una nueva', alertType: 'danger' })
      );
      return;
    }

    if (stock.measurement.measurement_name === '') {
      dispatch(
        setPopAlert({
          show: true,
          message: 'Por favor seleccione una unidad de medida o cree una nueva',
          alertType: 'danger',
        })
      );
      return;
    }

    if (stock.minimum_stock === 0) {
      dispatch(setPopAlert({ show: true, message: 'Por favor ingrese un stock mínimo', alertType: 'danger' }));
      return;
    }

    if (stock.product_name === '') {
      dispatch(setPopAlert({ show: true, message: 'Por favor ingrese el nombre del insumo', alertType: 'danger' }));
      return;
    }

    if (stock.groups.length === 0) {
      dispatch(setPopAlert({ show: true, message: 'Por favor seleccione al menos un rol', alertType: 'danger' }));
      return;
    }

    if (stock.process_roles.length === 0) {
      dispatch(
        setPopAlert({ show: true, message: 'Por favor seleccione al menos un rol de proceso', alertType: 'danger' })
      );
      return;
    }

    if (stock.id === 0) {
      const response = await apiCall('POST', 'stock/', stock, 'Stock guardado con éxito', 'Error al guardar el stock');
      if (response) {
        navigate('/insumos/');
      }
    } else {
      handleEditar();
    }
  };

  const handleEditar = async () => {
    const response = await apiCall(
      'PUT',
      `stock/${id}/`,
      stock,
      'Stock actualizado con éxito',
      'Error al actualizar el stock'
    );
    if (response) {
      navigate('/insumos/');
    }
  };

  const handleChangeGroups = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const selectedGroup = groups.find((group: GroupType) => group.name === name);
    if (!selectedGroup) return;

    setStock((prevStock) => {
      const userGroups = prevStock?.groups || [];
      const updatedGroups = checked
        ? [...userGroups, selectedGroup] // Agregar grupo si está seleccionado
        : userGroups.filter((group: GroupType) => group.name !== name); // Quitar grupo si no está seleccionado

      return { ...prevStock, groups: updatedGroups };
    });
  };

  const handleChangeProcessesRoles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    const selectedProcessRole = process_roles.find((role: ProcessRoleType) => role.name === name);

    if (!selectedProcessRole) return;

    setStock((prevStock) => {
      const userProcessRoles = prevStock?.process_roles || [];
      const updatedProcessRoles = checked
        ? [...userProcessRoles, selectedProcessRole]
        : userProcessRoles.filter((role: ProcessRoleType) => role.name !== name);

      return { ...prevStock, process_roles: updatedProcessRoles };
    });
  };

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} className='text-left'>
          <h2 className='text-left'>{stock.product_name.length > 0 ? `${stock.product_name}` : 'Nuevo Insumo'}</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-center'></Grid>
        <Grid item xs={12} sm={1} className='d-flex-center icon-btn'></Grid>
        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-right'>
          <Button className='btn btn-primary' onClick={() => navigate('/insumos/')}>
            Ir a Insumos
          </Button>
        </Grid>
      </Grid>
      <div className='form-container'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Categoría</Label>
              <>
                {!enableNewCategory ? (
                  <>
                    <Autocomplete
                      options={categories}
                      getOptionLabel={(option) => option.category_name}
                      value={stock.category}
                      onChange={(event, newValue) => {
                        setStock({
                          ...stock,
                          category: newValue || { id: 0, category_name: '' },
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    ¿No encuentra la categoría?{' '}
                    <span className='create-item-a' onClick={() => setEnableNewCategory(true)}>
                      Crear nueva categoría
                    </span>
                  </>
                ) : (
                  <>
                    <TextField
                      className='form-control'
                      type='text'
                      value={stock.category.category_name}
                      placeholder='Escriba el nombre de la nueva categoría'
                      onChange={(event) => {
                        setStock({
                          ...stock,
                          category: { id: 0, category_name: event.target.value },
                        });
                      }}
                    />
                    <span className='create-item-a' onClick={() => setEnableNewCategory(false)}>
                      Cancelar
                    </span>
                  </>
                )}
              </>
            </div>
            <div className='form-group'>
              <Label>Unidad de Medida</Label>
              <>
                {!enableNewMeasurement ? (
                  <>
                    <Autocomplete
                      options={measurements}
                      getOptionLabel={(option) => option.measurement_name}
                      value={stock.measurement}
                      onChange={(event, newValue) => {
                        setStock({
                          ...stock,
                          measurement: newValue || { id: 0, measurement_name: '' },
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    ¿No encuentra la unidad de medida?{' '}
                    <span className='create-item-a' onClick={() => setEnableNewMeasurement(true)}>
                      Crear nueva unidad de medida
                    </span>
                  </>
                ) : (
                  <>
                    <TextField
                      className='form-control'
                      type='text'
                      value={stock.measurement.measurement_name}
                      placeholder='Escriba el nombre de la nueva unidad de medida'
                      onChange={(event) => {
                        setStock({
                          ...stock,
                          measurement: { id: 0, measurement_name: event.target.value },
                        });
                      }}
                    />
                    <span className='create-item-a' onClick={() => setEnableNewMeasurement(false)}>
                      Cancelar
                    </span>
                  </>
                )}
              </>
            </div>
            <div className='form-group'>
              <Label>Stock Mínimo</Label>
              <TextField
                className='form-control'
                type='number'
                value={stock.minimum_stock}
                onFocus={(event) => event.target.select()}
                onChange={(event) => {
                  setStock({
                    ...stock,
                    minimum_stock: parseInt(event.target.value),
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Nombre del Insumo</Label>
              <TextField
                className='form-control'
                type='text'
                value={stock.product_name}
                onChange={(event) => {
                  setStock({
                    ...stock,
                    product_name: event.target.value,
                  });
                }}
              />
            </div>
            <div className='form-group'>
              <Label>Descripción del Insumo</Label>
              <TextField
                className='form-control'
                type='text'
                value={stock.product_description}
                onChange={(event) => {
                  setStock({
                    ...stock,
                    product_description: event.target.value,
                  });
                }}
              />
            </div>
            <div>
              <Label>Permisos</Label>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className='text-left'>
                  <div className='form-group'>
                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel component='legend'>Roles</FormLabel>
                      <FormGroup>
                        {groups.map((role: GroupType, index: number) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!stock?.groups?.find((group: GroupType) => group.id === role.id)} // Booleano explícito
                                onChange={handleChangeGroups}
                                name={role.name}
                              />
                            }
                            label={role.name}
                            key={index}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className='text-left'>
                  <div className='form-group'>
                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel component='legend'>Roles de Procesos</FormLabel>
                      <FormGroup>
                        {process_roles.map((role) => (
                          <FormControlLabel
                            key={role.id}
                            control={
                              <Switch
                                name={role.name} // Verificar que el `name` es correcto
                                checked={!!stock.process_roles.find((r) => r.name === role.name)}
                                onChange={handleChangeProcessesRoles}
                              />
                            }
                            label={role.name}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      {stock.id !== 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className='text-right'>
            <span className='text-log'>
              {stock.updated_at && (
                <>
                  <b>Última Modificación:</b> {new Date(stock.updated_at).toLocaleString('es-CO')}
                </>
              )}{' '}
              - <b>Creado por:</b> {stock.created_by && stock.created_by.username}
            </span>
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className='text-right'>
          {canAdd_stock && (
            <Button className='btn btn-primary' onClick={handleSave}>
              Guardar
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateOrEditStock;
