import { Grid } from '@mui/material';
import DataTable from '../../components/shared/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AttributesType, ScheduledType } from '../../types';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { usePermissions } from '../../services';
import { useAppContext } from '../../Context';

const ViewScheduled: React.FC = () => {
  const { apiCall } = useAppContext();
  const navigate = useNavigate();
  const [dates, setDates] = useState<ScheduledType[]>([]);
  const [total_days, setTotalDays] = useState<number>(0);
  const [total_pending_clothing, setTotalPendingClothing] = useState<number>(0);

  const { canView_orders } = usePermissions(['view_orders']);

  useEffect(
    () => {
      if (!canView_orders) {
        navigate('/');
      }
      getDates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getDates = async () => {
    const response = await apiCall('GET', 'orders/scheduled/', null, '', 'Error al obtener las fechas');
    if (response) {
      let total_days = 0;
      let total_pending_clothing = 0;
      response.forEach((date: ScheduledType) => {
        total_days += 1;
        total_pending_clothing += date.total_clothing_quantity - date.finished_clothing_quantity;
      });
      setTotalDays(total_days);
      setTotalPendingClothing(total_pending_clothing);
      setDates(response);
    }
  };

  const columns = [
    { title: 'Fecha', field: 'delivery_date', cell_class: 'text-center' },
    { title: 'Cantidad', field: 'total_clothing_quantity', cell_class: 'text-center' },
    { title: 'Cantidad Finalizada', field: 'finished_clothing_quantity', cell_class: 'text-center' },
  ];

  const actions: Array<any> = [
    {
      icon: <FontAwesomeIcon icon={faEye} />,
      tooltip: 'Ver',
      onClick: (rowData: ScheduledType) => {
        navigate(`/agenda/${rowData.delivery_date}`);
      },
    },
  ];

  const filterDate: Array<AttributesType> = [{ value: 'delivery_date', name: 'Fecha de Entrega' }];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className='text-left'>
          <h2 className='text-left'>Agenda</h2>
        </Grid>
        <Grid item xs={12} sm={4} className='text-center'>
          <b>Días Agendados</b>
          <h2 style={{ margin: 0 }}>{total_days}</h2>
        </Grid>
        <Grid item xs={12} sm={4} className='text-center'>
          <b>No. Prendas Activas</b>
          <h2 style={{ margin: 0 }}>{total_pending_clothing}</h2>
        </Grid>
      </Grid>
      <DataTable columns={columns} data={dates} actions={actions} filterByDateBy={filterDate} />
    </div>
  );
};

export default ViewScheduled;
