import { Autocomplete, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StockOutcomeType, StockSimpleType, StockType } from '../../types';
import { useAppContext } from '../../Context';
import { Button, Label } from '../../components/shared/FormElements';
import { usePermissions } from '../../services';
import { useAppDispatch } from '../../hooks';
import { setPopAlert } from '../../slices/miscSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const OutcomeSupply: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { canChange_stock } = usePermissions(['change_stock']);

  const [stock, setStock] = useState<StockType>({
    id: 0,
    product_name: '',
    product_description: '',
    category: { id: 0, category_name: '' },
    measurement: { id: 0, measurement_name: '' },
    minimum_stock: 0,
    groups: [],
    process_roles: [],
  });

  const [stockOutcome, setStockOutcome] = useState<StockOutcomeType>({
    quantity: 0,
    orders_ids: [],
    copy_for_stock: 0,
    comment: '',
  });

  const [stockOutcomeCopy, setStockOutcomeCopy] = useState<StockSimpleType[]>([]);

  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) {
      navigate('/insumos/');
      return;
    }

    // Fetch data
    getSupply(id);
    getSupplies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getSupply = async (id: string) => {
    const response = await apiCall('GET', `stock/${id}/`, null, '', 'Error al obtener el stock');
    if (response) {
      setStock(response);
    }
  };

  const getSupplies = async () => {
    const response = await apiCall('GET', `stock/get_all_simple/${id}`, null, '', 'Error al obtener los productos');
    if (response) {
      if (response.length !== 0) {
        setStockOutcomeCopy(response);
      }
    }
  };

  const handleSave = async () => {
    if (stockOutcome.quantity === 0) {
      dispatch(setPopAlert({ show: true, message: 'La cantidad es obligatoria', alertType: 'danger' }));
      return;
    }

    if (stockOutcome.orders_ids.length === 0 && stockOutcome.comment?.length === 0) {
      dispatch(
        setPopAlert({
          show: true,
          message: 'Debe agregar al menos un número de orden o una novedad',
          alertType: 'danger',
        })
      );
      return;
    }

    // recorrer los números de orden y verificar que no sean 0, verificar que sean solo numeros sin repetir, sin simbolos y sin letras
    const orders = stockOutcome.orders_ids.filter((order_id) => order_id !== 0);
    if (orders.length !== new Set(orders).size) {
      dispatch(setPopAlert({ show: true, message: 'Los números de orden no pueden repetirse', alertType: 'danger' }));
      return;
    }

    const regex = /^[0-9]*$/;
    for (let i = 0; i < orders.length; i++) {
      if (!regex.test(orders[i].toString())) {
        dispatch(
          setPopAlert({ show: true, message: 'Los números de orden deben ser solo números', alertType: 'danger' })
        );
        return;
      }
    }

    const response = await apiCall('POST', `stock/outcome/${id}/`, stockOutcome, '', 'Error al registrar la salida');
    if (response) {
      navigate('/insumos/');
    }
  };

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='text-left'>
          <h2 className='text-left'>
            Registrar salida para {stock.product_name.length > 0 ? `${stock.product_name}` : null}
          </h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-right'>
          <Button className='btn btn-primary' onClick={() => navigate('/insumos/')}>
            Ir a Insumos
          </Button>
        </Grid>
      </Grid>
      <div className='form-container'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Cantidad</Label>
              <TextField
                className='form-control'
                type='number'
                value={stockOutcome.quantity}
                onChange={(e) => setStockOutcome({ ...stockOutcome, quantity: parseFloat(e.target.value) })}
                onFocus={(e) => e.target.select()}
                style={{ marginBottom: '5px', width: '80%' }}
              />
              <span style={{ fontSize: '0.8em', display: 'flex' }}>en {stock.measurement.measurement_name}</span>
            </div>
            <div className='form-group'>
              <Label>Números de orden</Label>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {stockOutcome.orders_ids.map((order_id, index) => (
                  <li key={index}>
                    <TextField
                      className='form-control'
                      type='number'
                      value={order_id}
                      onChange={(e) => {
                        const newOrders = [...stockOutcome.orders_ids];
                        newOrders[index] = parseInt(e.target.value);
                        setStockOutcome({ ...stockOutcome, orders_ids: newOrders });
                      }}
                      onFocus={(e) => e.target.select()}
                      style={{ marginBottom: '5px', width: '80%' }}
                    />
                    <Button
                      onClick={() => {
                        const newOrders = [...stockOutcome.orders_ids];
                        newOrders.splice(index, 1);
                        setStockOutcome({ ...stockOutcome, orders_ids: newOrders });
                      }}
                      style={{ marginLeft: '5px', width: '10%' }}
                    >
                      <FontAwesomeIcon icon={faTrash} className='text-danger' />
                    </Button>
                  </li>
                ))}
                <li>
                  <Button
                    className='btn btn-secondary'
                    style={{ width: '300px', marginRight: '0', marginLeft: '0' }}
                    onClick={() => setStockOutcome({ ...stockOutcome, orders_ids: [...stockOutcome.orders_ids, 0] })}
                  >
                    Agregar número de orden
                  </Button>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Crear Copia de salida para este insumo:</Label>
              {stockOutcomeCopy?.length > 0 ? (
                <Autocomplete
                  options={stockOutcomeCopy}
                  getOptionLabel={(option) => option.product_name}
                  onChange={(e, value) => setStockOutcome({ ...stockOutcome, copy_for_stock: value ? value.id : 0 })}
                  value={stockOutcomeCopy.find((option) => option.id === stockOutcome.copy_for_stock)}
                  renderInput={(params) => <TextField {...params} />}
                />
              ) : (
                <p>No hay insumos para copiar</p>
              )}
            </div>
            <div className='form-group'>
              <Label>Si no hay ordenes relacionadas entonces escriba brevemente la novedad</Label>
              <TextField
                fullWidth
                multiline
                rows={14}
                value={stockOutcome.comment}
                onChange={(e) => setStockOutcome({ ...stockOutcome, comment: e.target.value })}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className='text-right'>
          {canChange_stock && (
            <Button className='btn btn-primary' onClick={handleSave}>
              Guardar
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default OutcomeSupply;
