import { Autocomplete, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StockIncomeType, StockType, VendorType } from '../../types';
import { useAppContext } from '../../Context';
import { Button, Label } from '../../components/shared/FormElements';
import { usePermissions } from '../../services';
import { useAppDispatch } from '../../hooks';
import { setPopAlert } from '../../slices/miscSlice';

const IncomeSupply: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { canChange_stock } = usePermissions(['change_stock']);

  const [stock, setStock] = useState<StockType>({
    id: 0,
    product_name: '',
    product_description: '',
    category: { id: 0, category_name: '' },
    measurement: { id: 0, measurement_name: '' },
    minimum_stock: 0,
    groups: [],
    process_roles: [],
  });

  const [stockIncome, setStockIncome] = useState<StockIncomeType>({
    quantity: 0,
    price: 0,
    vendor: {
      id: 0,
      vendor_name: '',
    },
    invoice: '',
  });

  const [vendors, setVendors] = useState<VendorType[]>([]);
  const [enableNewVendor, setEnableNewVendor] = useState<boolean>(false);

  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) {
      navigate('/insumos/');
      return;
    }

    // Fetch data
    getSupply(id);

    getVendors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getSupply = async (id: string) => {
    const response = await apiCall('GET', `stock/${id}/`, null, '', 'Error al obtener el stock');
    if (response) {
      setStock(response);
    }
  };

  const getVendors = async () => {
    const response = await apiCall('GET', 'vendors/', null, '', 'Error al obtener los proveedores');
    if (response) {
      setVendors(response);
    }
  };

  const handleSave = async () => {
    if (stockIncome.quantity === 0 || stockIncome.price === 0 || stockIncome.vendor.vendor_name.length === 0) {
      dispatch(setPopAlert({ show: true, message: 'Todos los campos son requeridos', alertType: 'danger' }));

      return;
    }

    const response = await apiCall('POST', `stock/income/${id}/`, stockIncome, 'data', 'Error al registrar la entrada');
    if (response) {
      navigate('/insumos/');
    }
  };

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='text-left'>
          <h2 className='text-left'>
            Registrar entrada para {stock.product_name.length > 0 ? `${stock.product_name}` : null}
          </h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-center'></Grid>

        <Grid item xs={12} sm={2} className='text-right'>
          <Button className='btn btn-primary' onClick={() => navigate('/insumos/')}>
            Ir a Insumos
          </Button>
        </Grid>
      </Grid>
      <div className='form-container'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Cantidad</Label>
              <TextField
                className='form-control'
                type='number'
                value={stockIncome.quantity}
                onChange={(e) => setStockIncome({ ...stockIncome, quantity: parseFloat(e.target.value) })}
                onFocus={(e) => e.target.select()}
              />
            </div>
            <div className='form-group'>
              <Label>Precio por unidad</Label>
              <TextField
                className='form-control'
                type='number'
                value={stockIncome.price}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setStockIncome({ ...stockIncome, price: parseFloat(e.target.value) })}
              />
            </div>
            <div className='form-group'>
              <Label>Proveedor</Label>
              {!enableNewVendor ? (
                <>
                  <Autocomplete
                    options={vendors}
                    getOptionLabel={(option) => option.vendor_name}
                    value={stockIncome.vendor}
                    onChange={(e, value) =>
                      setStockIncome({ ...stockIncome, vendor: value ?? { id: 0, vendor_name: '' } })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                  ¿No encuentra el proveedor?{' '}
                  <span className='create-item-a' onClick={() => setEnableNewVendor(true)}>
                    Crear nuevo proveedor
                  </span>
                </>
              ) : (
                <TextField
                  className='form-control'
                  value={stockIncome.vendor.vendor_name}
                  onChange={(e) => setStockIncome({ ...stockIncome, vendor: { id: 0, vendor_name: e.target.value } })}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Factura</Label>
              <TextField
                className='form-control'
                value={stockIncome.invoice}
                onChange={(e) => setStockIncome({ ...stockIncome, invoice: e.target.value })}
              />
            </div>
            <div className='form-group'>
              <Label>Subtotal</Label>
              <TextField className='form-control' value={stockIncome.quantity * stockIncome.price} disabled />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className='text-right'>
          {canChange_stock && (
            <Button className='btn btn-primary' onClick={handleSave}>
              Guardar
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default IncomeSupply;
