import { Grid } from '@mui/material';
import { useAppContext } from '../../Context';
import React, { useEffect, useRef, useState } from 'react';
import { useGroups } from '../../services';
import { constants } from '../../constants';

const InputFile: React.FC<{ setFile: (file: File) => void }> = ({ setFile }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const file = files ? files[0] : null;
    if (file) {
      setFile(file);
      if (inputRef.current) {
        inputRef.current.value = ''; // Resetear el campo después de procesar el archivo
      }
    }
  };

  return (
    <div>
      <input ref={inputRef} type='file' accept='.xlsx, .xls' onChange={handleFileChange} />
    </div>
  );
};

const UploadExcelToOrder: React.FC<{ order_id: number; viewMode: boolean; statusOrder: string }> = ({
  order_id,
  viewMode,
  statusOrder,
}) => {
  const [fileLink, setFileLink] = useState<string | null>(null);
  const { isAdministrador } = useGroups(['Administrador']);
  const [inputFileKey, setInputFileKey] = useState(0);

  const { apiCall } = useAppContext();

  useEffect(() => {
    getExcelForOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExcelForOrder = async () => {
    const response = await apiCall('GET', `order/get_excel/${order_id}/`, null, '', 'Error al obtener el archivo');
    if (response) {
      setFileLink(response.file);
    }
  };

  const setFile = async (file: File) => {
    if (!file || !order_id) return;
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      isFormData: true,
    };

    const response = await apiCall(
      'POST',
      `order/upload_excel/${order_id}/`,
      formData,
      '',
      'Error al subir el archivo',
      options
    );
    if (response) {
      getExcelForOrder();
      setInputFileKey((prev) => prev + 1);
    }
  };
  return (
    <div className='form-container'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Archivo Excel</h3>
          {fileLink ? (
            <a href={fileLink} target='_blank' rel='noreferrer' download={`order${order_id.toString()}.xlsx`}>
              Descargar Excel
            </a>
          ) : null}
          {!isAdministrador &&
          viewMode &&
          (statusOrder === constants.orderStatus.CREADA || statusOrder === constants.orderStatus.POR_VALIDAR) ? (
            <InputFile setFile={setFile} key={inputFileKey} />
          ) : null}
          {isAdministrador && viewMode ? <InputFile setFile={setFile} key={inputFileKey} /> : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadExcelToOrder;
