import DataTable from '../../components/shared/DataTable';
import { AttributesType, ColumnsType, TransactionType } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { useAppContext } from '../../Context';
import { setTransaction, initialStateTransaction } from '../../slices/transactionsSlice';
import { useEffect, useState } from 'react';
import { useGroups, usePermissions } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import Grid from '@mui/material/Grid';
import { setPopConfirmation } from '../../slices/miscSlice';
import { Button } from '../../components/shared/FormElements';
import { constants } from '../../constants';

const ViewTransactions: React.FC<{ isFullyAssigned: boolean }> = ({ isFullyAssigned }) => {
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<TransactionType[]>([]);

  const { canView_transactions, canChange_transactions, canAdd_transactions, canDelete_transactions } = usePermissions([
    'view_transactions',
    'add_transactions',
    'change_transactions',
    'delete_transactions',
  ]);

  const { isAuxiliarContable } = useGroups(['Auxiliar Contable']);
  useEffect(
    () => {
      setTransactions([]);
      getTransactions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      setTransactions([]);
      getTransactions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFullyAssigned]
  );

  if (!canView_transactions) {
    navigate('/');
    return null;
  }

  const getTransactions = async () => {
    const response = await apiCall('GET', 'transactions/', null, '', 'Error al obtener los movimientos');
    if (response) {
      let transactionsArray: TransactionType[] = [];
      if (isFullyAssigned) {
        transactionsArray = response.filter(
          (t: TransactionType) => t.fully_assigned && t.status === constants.transactionStatus.VALIDADO
        );
      } else {
        transactionsArray = response.filter(
          (t: TransactionType) =>
            !t.fully_assigned || (t.fully_assigned && t.status !== constants.transactionStatus.VALIDADO)
        );
      }
      setTransactions(transactionsArray);
    }
  };

  const decoratorEstado = {
    Validado: 'success',
    'No Validado': 'info',
    Rechazado: 'error',
  };

  const columns: ColumnsType[] = [
    {
      title: 'Código',
      field: 'code',
    },
    {
      title: 'Valor',
      field: 'master_value',
      type: 'currencyformat',
    },
    {
      title: 'Valor Asignado',
      field: 'assigned_value',
      type: 'currencyformat',
    },
    {
      title: 'Ordenes Asignadas',
      field: 'order_ids',
      type: 'stringtoarray',
    },
    {
      title: 'Medio',
      field: 'account',
    },
    {
      title: 'Cliente',
      field: 'customer_name',
    },
    {
      title: 'Código Banco',
      field: 'master_bank_code',
    },
    {
      title: 'Vendedor',
      field: 'reported_by_username',
    },
    {
      title: 'Estado',
      field: 'status',
      type: 'decorator',
      decorator: decoratorEstado,
      cell_class: 'text-center',
    },
    {
      title: 'Fecha de ingreso',
      field: 'income_date',
      type: 'date',
    },
  ];

  const handleViewTransaction = (transaction: TransactionType) => {
    navigate(`/movimiento/${transaction.code}`);
  };

  const handleDeleteTransaction = (transaction: TransactionType) => {
    dispatch(
      setPopConfirmation({
        show: true,
        message: '¿Está seguro que desea eliminar el movimiento?',
        alertType: 'danger',
        okFunc: () => deleteTransaction(transaction.code),
      })
    );
  };
  const deleteTransaction = async (transaction_code: number) => {
    const response = await apiCall(
      'DELETE',
      `transaction/${transaction_code}/`,
      null,
      '',
      'Error al eliminar el movimiento'
    );
    if (response) {
      getTransactions();
    }
  };

  const handleValidateTransaction = async (transaction: TransactionType) => {
    const response = await apiCall(
      'PATCH',
      `transaction/${transaction.code}/validate/`,
      null,
      '',
      'Error al validar el movimiento'
    );
    if (response) {
      getTransactions();
    }
  };

  const handleRejectTransaction = async (transaction: TransactionType) => {
    const response = await apiCall(
      'PATCH',
      `transaction/${transaction.code}/reject/`,
      null,
      '',
      'Error al rechazar el movimiento'
    );
    if (response) {
      getTransactions();
    }
  };

  const actions: Array<any> = [
    canView_transactions
      ? {
          icon: <FontAwesomeIcon icon={faEye} />,
          tooltip: 'Ver',
          onClick: (rowData: TransactionType) => handleViewTransaction(rowData),
        }
      : null,
    isAuxiliarContable || canChange_transactions
      ? {
          icon: <FontAwesomeIcon icon={faCheck} />,
          tooltip: 'Validar',
          onClick: (rowData: TransactionType) => handleValidateTransaction(rowData),
          condition: { field: 'status', value: constants.transactionStatus.NO_VALIDADO, operator: '===' },
        }
      : null,
    isAuxiliarContable || canChange_transactions
      ? {
          icon: <FontAwesomeIcon icon={faTimes} />,
          tooltip: 'Rechazar',
          onClick: (rowData: TransactionType) => handleRejectTransaction(rowData),
          condition: { field: 'status', value: constants.transactionStatus.NO_VALIDADO, operator: '===' },
        }
      : null,
    canDelete_transactions
      ? {
          icon: <FontAwesomeIcon icon={faTrash} />,
          tooltip: 'Eliminar',
          onClick: (rowData: any) => handleDeleteTransaction(rowData),
        }
      : null,
  ];

  const filterDate: Array<AttributesType> = [
    { value: 'income_date', name: 'Fecha de Ingreso' },
    { value: 'created_at', name: 'Fecha de Creación' },
    { value: 'updated_at', name: 'Fecha de Actualización' },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>Movimientos {isFullyAssigned && 'Asignados'}</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          {canAdd_transactions && (
            <Button
              className='btn btn-primary'
              onClick={() => {
                dispatch(setTransaction(initialStateTransaction.transaction));
                navigate('/movimiento/crear');
              }}
            >
              Agregar
            </Button>
          )}
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        data={transactions}
        actions={actions}
        groupedOptionBy={'account'}
        filterByDateBy={filterDate}
      />
    </div>
  );
};

export default ViewTransactions;
