import { useEffect } from 'react';
import { useAppContext } from '../../Context';
import { AttributesType, ColumnsType, OrderType } from '../../types';

import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/shared/DataTable';

// hooks redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { setOrder, setOrders } from '../../slices/ordersSlice';
import { setLoading, setPopConfirmation } from '../../slices/miscSlice';

import { printOrderTable, usePermissions, useGroups } from '../../services';
import { Button } from '../../components/shared/FormElements';
import { Grid } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faPrint, faCheck } from '@fortawesome/free-solid-svg-icons';
import { constants } from '../../constants';

const ViewOrders: React.FC = () => {
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orders = useSelector((state: any) => state.orders.orders);

  const { isAdministrador, isJefedeVentas, isValidador, isVendedor } = useGroups([
    'Administrador',
    'Jefe de Ventas',
    'Validador',
    'Vendedor',
  ]);

  const { canView_orders, canAdd_orders, canDelete_orders } = usePermissions([
    'view_orders',
    'add_orders',
    'change_orders',
    'delete_orders',
  ]);
  useEffect(
    () => {
      if (isValidador && !isJefedeVentas && !isAdministrador && !isVendedor) {
        navigate('/ordenes/por-validar');
      }

      const getOrders = async () => {
        const response = await apiCall('GET', 'orders/', null, '', 'Error al obtener las ordenes');
        if (response) {
          dispatch(setOrders(response));
        }
      };

      getOrders();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!canView_orders) {
    navigate('/');
    return null;
  }

  const hoyOnlyDate = new Date();
  hoyOnlyDate.setHours(0, 0, 0, 0);

  const conditionDecorator = {
    operator: '<',
    value: hoyOnlyDate,
    class_decorator: 'text-danger',
    type: 'date',
    limit: constants.settings.limitDateDays,
  };

  const decoratorEstado = {
    Finalizado: 'success',
    Bloqueado: 'warning',
    Creada: 'info',
    Cancelada: 'error',
  };

  const columns: Array<ColumnsType> = [
    { title: 'ID', field: 'id' },
    { title: 'Cliente', field: 'customer_name' },
    { title: 'Línea', field: 'line_name', cell_class: 'text-center' },
    { title: 'Cons', field: 'consecutives_count', cell_class: 'text-center' },
    { title: 'Vendedor', field: 'vendedor', cell_class: 'text-center' },
    { title: 'Fecha', field: 'order_date', cell_class: 'text-center' },
    { title: 'Inicio Prod.', field: 'production_date_start', cell_class: 'text-center' },
    {
      title: 'Fin Prod.',
      field: 'production_date_end',
      type: 'conditional_decorator',
      decorator: conditionDecorator,
      cell_class: 'text-center',
    },
    {
      title: 'Entrega',
      field: 'delivery_date',
      type: 'conditional_decorator',
      decorator: conditionDecorator,
      cell_class: 'text-center',
    },
    {
      title: 'Estado',
      field: 'current_process',
      type: 'decorator',
      decorator: decoratorEstado,
      cell_class: 'text-center',
    },
  ];

  const handleViewOrder = (order: OrderType) => {
    dispatch(setOrder(order));
    navigate(`/orden/${order.id}`);
  };

  const handleDeleteOrder = (order: OrderType) => {
    dispatch(
      setPopConfirmation({
        show: true,
        message: '¿Está seguro que desea eliminar la orden?',
        alertType: 'danger',
        okFunc: () => deleteOrder(order.id),
      })
    );
  };
  const deleteOrder = async (order_id: number) => {
    const response = await apiCall('DELETE', `order/${order_id}/`, null, '', 'Error al eliminar la orden');
    if (response) {
      dispatch(setOrders(orders.filter((o: OrderType) => o.id !== order_id)));
    }
  };

  const handlePrintOrder = async (order: OrderType) => {
    let boceto = null;
    const resp = await apiCall('GET', `order/${order.id}/boceto/`, null, '', 'Error al obtener la imagen');
    if (resp?.image) {
      boceto = resp.image;
    }
    dispatch(setLoading(true));
    await printOrderTable(order, boceto);
    dispatch(setLoading(false));
  };

  const handleAddProcess = (order: OrderType) => {
    dispatch(
      setPopConfirmation({
        show: true,
        message: '¿Está seguro que desea enviar la orden a validación?',
        alertType: 'danger',
        okFunc: () => addProcess(order.id),
      })
    );
  };
  const addProcess = async (order_id: number) => {
    const response = await apiCall(
      'PUT',
      `order/${order_id}/validate/`,
      null,
      '',
      'Error al enviar la orden a validación'
    );
    if (response) {
      dispatch(setOrders(orders.filter((o: OrderType) => o.id !== order_id)));
    }
  };

  const ableToSendToValidation = isAdministrador || isJefedeVentas || isVendedor;

  const actions: Array<any> = [
    canView_orders
      ? {
          icon: <FontAwesomeIcon icon={faEye} />,
          tooltip: 'Ver',
          onClick: (rowData: OrderType) => handleViewOrder(rowData),
        }
      : null,
    canDelete_orders
      ? {
          icon: <FontAwesomeIcon icon={faTrash} />,
          tooltip: 'Eliminar',
          onClick: (rowData: any) => handleDeleteOrder(rowData),
        }
      : null,
    canView_orders
      ? {
          icon: <FontAwesomeIcon icon={faPrint} />,
          tooltip: 'Imprimir',
          onClick: (rowData: any) => handlePrintOrder(rowData),
        }
      : null,
    ableToSendToValidation
      ? {
          icon: <FontAwesomeIcon icon={faCheck} />,
          tooltip: 'Enviar a validación',
          onClick: (rowData: any) => handleAddProcess(rowData),
          condition: { field: 'status', value: constants.orderStatus.CREADA, operator: '===' },
        }
      : null,
  ];

  const filterDate: Array<AttributesType> = [
    { value: 'order_date', name: 'Fecha de Creación' },
    { value: 'production_date_start', name: 'Fecha de Inicio de Produccion' },
    { value: 'production_date_end', name: 'Fecha de Fin de Produccion' },
    { value: 'delivery_date', name: 'Fecha de Entrega' },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>Órdenes</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          {canAdd_orders && (
            <Button className='btn btn-primary' onClick={() => navigate('/orden/crear')}>
              Agregar
            </Button>
          )}
        </Grid>
      </Grid>
      <DataTable
        data={orders}
        columns={columns}
        actions={actions}
        groupedOptionBy={'current_process'}
        filterByDateBy={filterDate}
      />
    </div>
  );
};

export default ViewOrders;
