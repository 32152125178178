import { Grid } from '@mui/material';
import DataTable from '../../components/shared/DataTable';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../Context';
import { useGroups } from '../../services';
import { useNavigate } from 'react-router-dom';

const ViewAudit: React.FC = () => {
  const [auditlogs, setAuditlogs] = useState<any[]>([]);
  const { apiCall } = useAppContext();
  const navigate = useNavigate();
  const { isAdministrador } = useGroups(['Administrador']);

  const today = new Date()
    .toLocaleString('es-CO', {
      timeZone: 'America/Bogota', // UTC -5
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
    .reverse()
    .join('-');
  console.log(today);
  const [dateQuery, setDateQuery] = useState<string>(today);
  useEffect(() => {
    if (!isAdministrador) {
      navigate('/');
      return;
    }
    getAuditlogs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateQuery]);

  const getAuditlogs = async () => {
    if (!dateQuery) return;
    const response = await apiCall(
      'GET',
      `audit/users/${dateQuery}/`,
      null,
      '',
      'Error al obtener los registros de auditoría'
    );
    if (response) {
      setAuditlogs(response as any[]);
    }
  };

  const columns = [
    { field: 'user__username', title: 'Usuario', cell_class: 'text-center uppercase' },
    { field: 'first_action', title: 'Primera acción', cell_class: 'text-center italic' },
    { field: 'first_created_at', title: 'Hora', type: 'time', cell_class: 'text-center bold' },
    { field: 'last_action', title: 'Última acción', cell_class: 'text-center italic' },
    { field: 'last_created_at', title: 'Hora', type: 'time', cell_class: 'text-center bold' },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>Auditoría</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          <input
            type='date'
            value={dateQuery}
            onChange={(e) => setDateQuery(e.target.value)}
            className='form-control date-audit'
          />
        </Grid>
      </Grid>
      <DataTable columns={columns} data={auditlogs} actions={null} />
    </div>
  );
};

export default ViewAudit;
