import { Grid } from '@mui/material';
import DataTable from '../../components/shared/DataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../Context';
import { StockTransactionApproveType } from '../../types';
import { Button } from '../../components/shared/FormElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { usePermissions } from '../../services';

const OutcomeSupplyApprove: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useAppContext();
  useEffect(() => {
    if (!id) {
      navigate('/insumos/');
      return;
    }
    // Fetch data
    getSupplyOutcomePending(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { canChange_stock } = usePermissions(['change_stock']);

  const [stock, setStock] = useState<StockTransactionApproveType>({
    id: 0,
    product_quantity: 0,
    created_at: '',
    executed_by: '',
    order_ids: '',
  });

  const getSupplyOutcomePending = async (id: string) => {
    const response = await apiCall(
      'GET',
      `stock_transactions/${id}/pending/`,
      null,
      '',
      'Error al obtener el detalle del insumo'
    );
    if (response) {
      setStock(response);
    }
  };

  const columns = [
    { title: 'Cantidad', field: 'product_quantity', cell_class: 'text-center' },
    { title: 'Fecha', field: 'created_at', cell_class: 'text-center', type: 'datetime' },
    { title: 'Ejecutado por', field: 'executed_by__username', cell_class: 'text-center' },
    { title: 'Orden', field: 'order_ids', cell_class: 'text-center' },
  ];

  const actions = [
    canChange_stock && {
      icon: <FontAwesomeIcon icon={faCheck} />,
      tooltip: 'Aprobar',
      onClick: (rowData: StockTransactionApproveType) => {
        const approveSupply = async () => {
          const response = await apiCall(
            'PUT',
            `stock_transactions/${rowData.id}/approve/`,
            null,
            '',
            'Error al aprobar la salida'
          );
          if (response) {
            navigate('/insumos/');
          }
        };

        approveSupply();
      },
    },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>Detalle de Insumo </h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          <Button className='btn btn-primary' onClick={() => navigate('/insumos/')}>
            Ir a Insumos
          </Button>
        </Grid>
      </Grid>
      <DataTable columns={columns} data={stock} actions={actions} />
    </div>
  );
};

export default OutcomeSupplyApprove;
