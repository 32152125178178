import { Grid } from '@mui/material';
import { Button } from '../../components/shared/FormElements';
import { useAppContext } from '../../Context';
import { useAppDispatch } from '../../hooks';
import { usePermissions } from '../../services';
import { ListStockType } from '../../types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/shared/DataTable';
import { setPopConfirmation } from '../../slices/miscSlice';
import { faCheck, faComment, faEye, faPencil, faRightToBracket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ViewSupplies: React.FC = () => {
  const { apiCall } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [stock, setStock] = useState<ListStockType[]>([]);

  const { canView_stock, canAdd_stock, canChange_stock, canDelete_stock } = usePermissions([
    'view_stock',
    'add_stock',
    'change_stock',
    'delete_stock',
  ]);

  useEffect(() => {
    if (!canView_stock) {
      navigate('/');
      return;
    }
    if (canView_stock) {
      getStock();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStock = async () => {
    const response = await apiCall('GET', 'stock/get_all', null, '', 'Error al obtener el inventario');
    if (response) {
      setStock(response);
    }
  };

  const conditionDecorator = {
    operator: '<',
    value: 'minimum_stock',
    is_field: true,
    class_decorator: 'pill danger',
    type: 'number',
  };

  const columns = [
    { title: 'Tipo', field: 'category_name', cell_class: 'text-center' },
    { title: 'Nombre', field: 'product_name', cell_class: 'text-center' },
    { title: 'Medida', field: 'measurement_name', cell_class: 'text-center' },
    { title: 'Costo', field: 'current_cost', cell_class: 'text-center' },
    {
      title: 'En Stock',
      field: 'total_stock',
      cell_class: 'text-center',
      type: 'conditional_decorator',
      decorator: conditionDecorator,
    },
    { title: 'Stock Mín.', field: 'minimum_stock', cell_class: 'text-center' },
    { title: 'Pendiente', field: 'total_salida_pendiente', cell_class: 'text-center' },
  ];

  const actions = [
    canAdd_stock && {
      icon: <FontAwesomeIcon icon={faRightToBracket} />,
      tooltip: 'Ingreso',
      buttonStyle: { color: 'green' },
      iconStyle: { transform: 'rotate(90deg)' },
      onClick: (rowData: ListStockType) => {
        navigate(`/insumo/ingreso/${rowData.id}`);
      },
    },
    canChange_stock && {
      icon: <FontAwesomeIcon icon={faRightToBracket} />,
      tooltip: 'Salida',
      buttonStyle: { color: 'red' },
      iconStyle: { transform: 'rotate(270deg)' },
      onClick: (rowData: ListStockType) => {
        navigate(`/insumo/salida/${rowData.id}`);
      },
    },
    canChange_stock && {
      icon: <FontAwesomeIcon icon={faComment} />,
      tooltip: 'Novedad',
      onClick: (rowData: ListStockType) => {
        navigate(`/insumo/novedad/${rowData.id}`);
      },
    },
    canChange_stock && {
      icon: <FontAwesomeIcon icon={faCheck} />,
      tooltip: 'Aprobar Salida Pendiente',
      buttonStyle: { color: 'green' },
      onClick: (rowData: ListStockType) => {
        navigate(`/insumo/salida/aprobar/${rowData.id}`);
      },
      condition: {
        field: 'total_salida_pendiente',
        operator: '>',
        value: 0,
      },
    },
    {
      icon: <FontAwesomeIcon icon={faEye} />,
      tooltip: 'Ver',
      onClick: (rowData: ListStockType) => {
        navigate(`/insumo/detalle/${rowData.id}`);
      },
    },
    canAdd_stock && {
      icon: <FontAwesomeIcon icon={faPencil} />,
      tooltip: 'Editar',
      onClick: (rowData: ListStockType) => {
        navigate(`/insumo/editar/${rowData.id}`);
      },
    },
    canDelete_stock && {
      icon: <FontAwesomeIcon icon={faTrash} />,
      tooltip: 'Eliminar',
      onClick: (rowData: ListStockType) => {
        dispatch(
          setPopConfirmation({
            alertType: 'warning',
            message: '¿Está seguro que desea eliminar este insumo?',
            show: true,
            okFunc: async () => {
              const response = await apiCall(
                'DELETE',
                `stock/${rowData.id}/`,
                null,
                'Insumo eliminado con éxito',
                'Error al eliminar insumo'
              );
              if (response) {
                setStock(stock.filter((s) => s.id !== rowData.id));
              }
            },
          })
        );
      },
    },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>Inventario de Insumos</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          {canAdd_stock && (
            <Button
              className='btn btn-primary'
              onClick={() => {
                navigate('/insumo/crear');
              }}
            >
              Agregar
            </Button>
          )}
        </Grid>
      </Grid>
      <DataTable columns={columns} data={stock} actions={actions} groupedOptionBy={'category_name'} />
    </div>
  );
};

export default ViewSupplies;
